import React from 'react';

// === Components === //
import { Field } from 'formik';

// === Styles === //
import styled from '@emotion/styled';
import { colors } from '@styles';

const CheckboxStyled = styled(Field)`
  position: relative;
  width: 34px;
  height: 34px;
  border: none;
  margin: 0;
  cursor: pointer;
  border-radius: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &::before {
    border: 1px solid ${colors.ui.grayDark};
    border-radius: 3px;
    background-color: ${colors.white};
    border-radius: 100%;
  }

  &::after {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background-color: ${colors.ui.grayDark};
    margin: auto;
    display: none;
  }

  &:checked {
    &::before {
      background-color: ${colors.ui.main};
    }

    &::after {
      display: block;
    }
  }
`;

interface RadioProps {
  name: string;
  value: string;
}

const Radio = ({ name, value }: RadioProps) => {
  return <CheckboxStyled type="radio" name={name} value={value} />;
};

export default Radio;
