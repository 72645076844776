import React from 'react';

// === Components === //
import Page from '@components/layout/Page';
import MakeAnApplicationTitle from '@components/sections/MakeAnApplicationTitle';
import MakeAnApplicationForm from '@components/sections/MakeAnApplicationForm';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MakeAnApplication = () => {
  const { t } = useTranslation();

  const metaData = {
    title: t('maa_meta_title'),
    description: t('maa_meta_description'),
    keywords: t('maa_meta_keywords'),
  };

  return (
    <Page metaData={metaData} activePage="application">
      <div>
        <MakeAnApplicationTitle />
        <MakeAnApplicationForm />
      </div>
    </Page>
  );
};

export default MakeAnApplication;
