import React, { useEffect, useState } from 'react';

// === Components === //
import { Form, Formik, FormikProps } from 'formik';
import Container from '@components/common/Container';
import FormikInput from '@components/common/FormikInput';
import FormikTextarea from '@components/common/FormikTextarea';
import FormikDatepicker from '@components/common/FormikDatepicker';
import FormikCheckboxList from '@components/common/FormikCheckboxList';
import FormikRadioList from '@components/common/FormikRadioList';
import FormikAgreementsList from '@components/common/FormikAgreementsList';
import Button from '@components/common/Button';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';
import dateFormat from 'dateformat';
import axios from 'axios';
import * as yup from 'yup';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, respondFrom } from '@styles';
import { css } from '@emotion/react';

// === Types === //
import { FormValuesData } from '@type/form';

const MAAFWrapper = styled.div`
  padding-bottom: 50px;

  ${respondFrom(
    breakpoints.md,
    css`
      padding-bottom: 87px;
    `
  )}
`;

const MAAFButtonWrapper = styled.div`
  margin-top: 40px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-top: 60px;
    `
  )}
`;

const MAAFButtonInner = styled.div`
  display: inline-block;
`;

const MakeAnApplicationForm = () => {
  const [token, setToken] = useState('');
  const [buttonLoading, handleButtonLoading] = useState(false);
  const { t } = useTranslation();

  // uk cyrillic, pl signs, space, -
  const validationRegex =
    /^[\u0400-\u0484\u0487-\u052F\u1C80-\u1C88\u1D2B\u1D78\u2DE0-\u2DFF\uA640-\uA69F\uFE2E\uFE2F\s\-a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ]*$/;

  const handleFetchToken = () => {
    axios({
      method: 'GET',
      url: `${process.env.NETTO_HELP_UKRAINE_FORM_TOKEN}`,
      headers: {
        'Content-Type': 'text/plain',
      },
      responseType: 'text',
    })
      .then((response) => {
        setToken(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(`${t('maa_form_input_1_error_message_1')}`)
      .matches(validationRegex, {
        message: t('maa_form_input_1_error_message_2'),
        excludeEmptyString: true,
      })
      .max(250, `${t('maa_form_input_1_error_message_3')}`),
    lastName: yup
      .string()
      .required(`${t('maa_form_input_2_error_message_1')}`)
      .matches(validationRegex, {
        message: t('maa_form_input_2_error_message_2'),
        excludeEmptyString: true,
      })
      .max(250, `${t('maa_form_input_2_error_message_3')}`),
    phone: yup
      .string()
      .required(`${t('maa_form_input_3_error_message_1')}`)
      .matches(/^[0-9\+]*$/, {
        message: t('maa_form_input_3_error_message_2'),
        excludeEmptyString: true,
      }),
    email: yup
      .string()
      .required(`${t('maa_form_input_4_error_message_1')}`)
      .email(`${t('maa_form_input_4_error_message_2')}`),
    description: yup
      .string()
      .required(`${t('maa_form_input_5_error_message_1')}`)
      .max(1200, `${t('maa_form_input_5_error_message_2')}`),
    date: yup.string().required(`${t('maa_form_input_6_error_message_1')}`),
    place: yup
      .string()
      .required(`${t('maa_form_input_7_error_message_1')}`)
      .max(250, `${t('maa_form_input_7_error_message_2')}`),
    money: yup
      .number()
      .required(`${t('maa_form_input_8_error_message_1')}`)
      .max(15000, `${t('maa_form_input_8_error_message_2')}`)
      .positive(`${t('maa_form_input_8_error_message_3')}`),
    target: yup.array().min(1, `${t('maa_form_input_9_error_message_1')}`),
    whoDoYouWantToHelp: yup
      .string()
      .required(`${t('maa_form_input_10_error_message_1')}`)
      .max(250, `${t('maa_form_input_10_error_message_2')}`),
    entityName: yup
      .string()
      .required(`${t('maa_form_input_12_error_message_1')}`)
      .max(250, `${t('maa_form_input_12_error_message_2')}`),
    agreements: yup.array().min(2, `${t('maa_form_agreements_error_message')}`),
  });

  const handleSubmitValidationScroll = (props: FormikProps<FormValuesData>) => {
    const errors = props.errors;
    const errorsKeys = Object.keys(errors);

    validationSchema.isValid(props.values).then((valid) => {
      if (!valid) {
        // for unknown reason, if none of the fields is changed, it will not return errors after sending the form
        const firstErrorKey = errorsKeys.length > 0 ? errorsKeys[0] : 'firstName';
        const errorElement = document.querySelector(`body [name="${firstErrorKey}"]`);

        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    });
  };

  useEffect(() => {
    handleFetchToken();
  }, []);

  return (
    <MAAFWrapper>
      <Container>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            description: '',
            date: '',
            place: '',
            money: '',
            target: [],
            whoDoYouWantToHelp: '',
            whoAreYou: `${t('maa_form_input_11_option_1_value')}`,
            entityName: '',
            agreements: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleButtonLoading(true);
            const input = {
              type: [
                {
                  target_id: 'zgloszenie',
                },
              ],
              title: [
                {
                  value: 'Zgloszenie',
                },
              ],
              field_cel: values.target.map((t) => ({
                value: t,
              })),
              field_email: [
                {
                  value: values.email,
                },
              ],
              field_imie: [
                {
                  value: values.firstName,
                },
              ],
              field_kim_jestes: [
                {
                  value: values.whoAreYou,
                },
              ],
              field_komu_chcesz_pomoc: [
                {
                  value: values.whoDoYouWantToHelp,
                },
              ],
              field_kwota: [
                {
                  value: values.money,
                },
              ],
              field_miasto: [
                {
                  value: values.place,
                },
              ],
              field_nazwa_podmiotu: [
                {
                  value: values.entityName,
                },
              ],
              field_nazwisko: [
                {
                  value: values.lastName,
                },
              ],
              field_opis: [
                {
                  value: values.description,
                },
              ],
              field_telefon: [
                {
                  value: values.phone,
                },
              ],
              field_termin_realizacji: [
                {
                  value: dateFormat(values.date, 'yyyy-mm-dd'),
                },
              ],
              field_zgodaregulamin: [
                {
                  value: values.agreements.find((a) => a === '2') ? true : false,
                },
              ],
              field_zgoda_przetwarzanie_danych: [
                {
                  value: values.agreements.find((a) => a === '1') ? true : false,
                },
              ],
              field_lang: [
                {
                  value: `${t('language')}`,
                },
              ],
            };

            axios({
              method: 'POST',
              url: `${process.env.NETTO_HELP_UKRAINE_FORM_ENDPOINT}`,
              headers: {
                'Content-type': 'application/json',
                'X-CSRF-Token': token,
              },
              data: JSON.stringify({ ...input }),
            })
              .then((response) => {
                handleButtonLoading(false);

                if (typeof window !== 'undefined' && response && response.status === 201) {
                  let url = '';

                  if (t('language') === 'pl') {
                    url = '/zgloszenie-przyjete';
                  } else {
                    url = `/${t('language')}/zgloszenie-przyjete`;
                  }
                  console.log(url);
                  window.location = url as any;
                }
              })
              .catch((error) => {
                handleButtonLoading(false);
                console.error(error);
              });
          }}
        >
          {(props: FormikProps<FormValuesData>) => (
            <Form>
              <FormikInput
                type="text"
                name="firstName"
                placeholder={t('maa_form_input_1_placeholder')}
                label={t('maa_form_input_1_label')}
              />
              <FormikInput
                type="text"
                name="lastName"
                placeholder={t('maa_form_input_2_placeholder')}
                label={t('maa_form_input_2_label')}
              />
              <FormikInput
                type="text"
                name="phone"
                placeholder={t('maa_form_input_3_placeholder')}
                label={t('maa_form_input_3_label')}
              />
              <FormikInput
                type="text"
                name="email"
                placeholder={t('maa_form_input_4_placeholder')}
                label={t('maa_form_input_4_label')}
              />
              <FormikTextarea
                name="description"
                placeholder={t('maa_form_input_5_placeholder')}
                label={t('maa_form_input_5_label')}
                sublabel={t('maa_form_input_5_sublabel')}
              />
              <FormikDatepicker
                name="date"
                placeholder={t('maa_form_input_6_placeholder')}
                label={t('maa_form_input_6_label')}
              />
              <FormikInput
                type="text"
                name="place"
                placeholder={t('maa_form_input_7_placeholder')}
                label={t('maa_form_input_7_label')}
              />
              <FormikInput
                type="number"
                name="money"
                placeholder={t('maa_form_input_8_placeholder')}
                label={t('maa_form_input_8_label')}
                sublabel={t('maa_form_input_8_sublabel')}
                rightText={t('maa_form_input_8_right_text')}
              />
              <FormikCheckboxList
                name="target"
                label={t('maa_form_input_9_label')}
                list={[
                  {
                    name: t('maa_form_input_9_option_1'),
                    value: t('maa_form_input_9_option_1_value'),
                  },
                  {
                    name: t('maa_form_input_9_option_2'),
                    value: t('maa_form_input_9_option_2_value'),
                  },
                  {
                    name: t('maa_form_input_9_option_3'),
                    value: t('maa_form_input_9_option_3_value'),
                  },
                ]}
              />
              <FormikInput
                type="text"
                name="whoDoYouWantToHelp"
                placeholder={t('maa_form_input_10_placeholder')}
                label={t('maa_form_input_10_label')}
              />
              <FormikRadioList
                name="whoAreYou"
                label={t('maa_form_input_11_label')}
                list={[
                  {
                    name: t('maa_form_input_11_option_1'),
                    value: t('maa_form_input_11_option_1_value'),
                  },
                  {
                    name: t('maa_form_input_11_option_2'),
                    value: t('maa_form_input_11_option_2_value'),
                  },
                  {
                    name: t('maa_form_input_11_option_3'),
                    value: t('maa_form_input_11_option_3_value'),
                  },
                  {
                    name: t('maa_form_input_11_option_4'),
                    value: t('maa_form_input_11_option_4_value'),
                  },
                  {
                    name: t('maa_form_input_11_option_5'),
                    value: t('maa_form_input_11_option_5_value'),
                  },
                ]}
              />
              <FormikInput
                type="text"
                name="entityName"
                placeholder={t('maa_form_input_12_placeholder')}
                label={t('maa_form_input_12_label')}
              />
              <FormikAgreementsList
                name="agreements"
                label={t('maa_form_agreement_label')}
                list={[
                  {
                    title: t('maa_form_agreement_1_title'),
                    description: t('maa_form_agreement_1_description'),
                    value: '1',
                  },
                  {
                    title: t('maa_form_agreement_2_title'),
                    description: t('maa_form_agreement_2_description'),
                    value: '2',
                    list: [
                      t('maa_form_agreement_2_description_li_1'),
                      t('maa_form_agreement_2_description_li_2'),
                      t('maa_form_agreement_2_description_li_3'),
                    ],
                  },
                ]}
              />

              <MAAFButtonWrapper>
                <MAAFButtonInner onClick={() => handleSubmitValidationScroll(props)}>
                  <Button type="submit" disabled={buttonLoading}>
                    <>{t('maa_form_submit_cta_label')}</>
                  </Button>
                </MAAFButtonInner>
              </MAAFButtonWrapper>
            </Form>
          )}
        </Formik>
      </Container>
    </MAAFWrapper>
  );
};

export default MakeAnApplicationForm;
