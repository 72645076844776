import React from 'react';

// === Components === //
import { Field } from 'formik';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, dimensions, fonts, respondFrom } from '@styles';
import { css } from '@emotion/react';

const Input = styled.div`
  display: block;
  position: relative;
  margin: 0 0 40px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin: 0 0 48px;
    `
  )}

  label {
    display: inline-block;
    color: ${colors.text.default};
    font-size: ${dimensions.fontSize.tiny}px;
    text-transform: uppercase;
    margin-bottom: 7px;
    line-height: 17px;
  }

  input {
    display: block;
    border: 1px solid ${colors.ui.grayDark};
    background-color: ${colors.white};
    font-family: ${fonts.default};
    font-size: ${dimensions.fontSize.regular}px;
    font-weight: 300;
    color: ${colors.text.default};
    outline: none;
    padding: 16px 28px 15px 20px;
    width: 100%;
    max-width: 612px;
    border-radius: 3px;

    &::placeholder {
      color: ${colors.text.placeholder};
      font-family: ${fonts.default};
    }

    &:focus {
      border-color: ${colors.text.default};
    }

    &.is-error {
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

const Sublabel = styled.p`
  font-size: 15px;
  margin: 4px 0 0;
  line-height: 18px;
`;

const Error = styled.p`
  color: ${colors.text.error};
  font-size: 15px;
  margin: 4px 0 0;
  line-height: 18px;
`;

const InputWrapper = styled.div`
  position: relative;

  &.size-small {
    max-width: 400px;
  }
`;

const RightText = styled.span`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  right: 20px;
  font-size: 19px;
`;

interface FormikInputProps {
  type: string;
  name: string;
  placeholder: string;
  label: string;
  sublabel?: string;
  rightText?: string;
}

const FormikInput = ({ type, name, placeholder, label, sublabel, rightText }: FormikInputProps) => {
  return (
    <div>
      <Field name={name}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => {
          return (
            <Input>
              <label>{label}</label>
              <InputWrapper className={rightText ? 'size-small' : ''}>
                <input
                  type={type}
                  {...field}
                  className={`${meta.touched && meta.error ? 'is-error' : ''}`}
                  placeholder={placeholder}
                />
                {rightText && <RightText>{rightText}</RightText>}
              </InputWrapper>
              {sublabel && (
                <div>
                  <Sublabel>{sublabel}</Sublabel>
                </div>
              )}
              <div>
                {meta.touched && meta.error && (
                  <>
                    <Error>{meta.error}</Error>
                  </>
                )}
              </div>
            </Input>
          );
        }}
      </Field>
    </div>
  );
};

export default FormikInput;
