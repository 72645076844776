import React from 'react';

// === Components === //
import Checkbox from '@components/common/Checkbox';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, dimensions, fonts, respondFrom } from '@styles';
import { ErrorMessage } from 'formik';
import { css } from '@emotion/react';

const CheckboxList = styled.div`
  margin-bottom: 48px;
`;

const CheckboxListLabel = styled.label`
  display: block;
  color: ${colors.text.default};
  font-size: ${dimensions.fontSize.tiny}px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const CheckboxListWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

const CheckboxItemLabel = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      margin-bottom: 25px;

      &:last-of-type {
        margin-bottom: 0;
      }
    `
  )}

  > span {
    display: flex;
    margin-right: 16px;
  }
`;

const Error = styled.p`
  color: ${colors.text.error};
  font-size: 15px;
  margin: 4px 0 0;
  line-height: 18px;
`;

const LabelText = styled.p`
  margin: -5px 0 0;
  max-width: 562px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin: 5px 0 0;
    `
  )}
`;

const LabelTitle = styled.span`
  display: block;
  font-family: ${fonts.extraBold};
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 800;

  ${respondFrom(
    breakpoints.md,
    css`
      font-size: ${dimensions.fontSize.regular}px;
      line-height: 23px;
    `
  )}
`;

const LabelDescription = styled.span`
  display: block;
  font-size: ${dimensions.fontSize.tiny}px;
  line-height: 17px;
`;

const LabelDescriptionList = styled.ul`
  margin-top: 10px;

  li {
    font-size: ${dimensions.fontSize.tiny}px;
    line-height: 17px;
  }
`;

interface FormikAgreementsListProps {
  name: string;
  label: string;
  list: CheckboxData[];
}

interface CheckboxData {
  title: string;
  description: string;
  value: string;
  list?: string[];
}

const FormikAgreementsList = ({ name, label, list }: FormikAgreementsListProps) => {
  return (
    <CheckboxList>
      <CheckboxListLabel>{label}</CheckboxListLabel>
      <CheckboxListWrapper>
        {list.map((checkbox) => (
          <CheckboxItemLabel key={checkbox.title}>
            <span>
              <Checkbox name={name} value={checkbox.value} />
            </span>
            <LabelText>
              <LabelTitle>{checkbox.title}</LabelTitle>
              <LabelDescription>{checkbox.description}</LabelDescription>
              {checkbox.list && checkbox.list.length > 0 && (
                <LabelDescriptionList>
                  {checkbox.list?.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </LabelDescriptionList>
              )}
            </LabelText>
          </CheckboxItemLabel>
        ))}
      </CheckboxListWrapper>
      <ErrorMessage name={name}>{(msg) => <Error>{msg}</Error>}</ErrorMessage>
    </CheckboxList>
  );
};

export default FormikAgreementsList;
