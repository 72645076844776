import React from 'react';

// === Components === //
import Radio from '@components/common/Radio';
import Paragraph from '@components/common/Paragraph';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, dimensions, respondFrom } from '@styles';
import { ErrorMessage } from 'formik';
import { css } from '@emotion/react';

const CheckboxList = styled.div`
  margin-bottom: 40px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-bottom: 15px;
    `
  )}
`;

const CheckboxListLabel = styled.label`
  display: block;
  color: ${colors.text.default};
  font-size: ${dimensions.fontSize.tiny}px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const CheckboxListWrapper = styled.div`
  display: flex;
  flex-flow: column;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row wrap;
    `
  )}
`;

const CheckboxItemLabel = styled.label`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      margin-right: 39px;
    `
  )}
`;

const Error = styled.p`
  color: ${colors.text.error};
  font-size: 15px;
  margin: 4px 0 0;
  line-height: 18px;
`;

const CheckboxWrapper = styled.span`
  display: flex;
  margin-right: 16px;
`;

const LabelText = styled.span`
  display: inline-block;
  padding-top: 5px;

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 192px;
    `
  )}
`;

interface FormikRadioListProps {
  name: string;
  label: string;
  list: CheckboxData[];
}

interface CheckboxData {
  name: string;
  value: string;
}

const FormikRadioList = ({ name, label, list }: FormikRadioListProps) => {
  return (
    <CheckboxList>
      <CheckboxListLabel>{label}</CheckboxListLabel>
      <CheckboxListWrapper>
        {list.map((checkbox) => (
          <CheckboxItemLabel key={checkbox.value}>
            <CheckboxWrapper>
              <Radio name={name} value={checkbox.value} />
            </CheckboxWrapper>
            <LabelText>
              <Paragraph htmlAsString={checkbox.name} />
            </LabelText>
          </CheckboxItemLabel>
        ))}
      </CheckboxListWrapper>
      <ErrorMessage name={name}>{(msg) => <Error>{msg}</Error>}</ErrorMessage>
    </CheckboxList>
  );
};

export default FormikRadioList;
