import React, { useCallback, useEffect, useState } from 'react';

// === Assets === //
import caledarIconYellow from '@assets/svg/calendar-icon-yellow.svg';
import caledarIconBlack from '@assets/svg/calendar-icon-black.svg';

// === Components === //
import { Field } from 'formik';
import DatePicker from 'react-datepicker';
import MaskedTextInput from '@biproxi/react-text-mask';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { registerLocale } from 'react-datepicker';
import localePl from 'date-fns/locale/pl';
import localeUA from 'date-fns/locale/uk';

// === Styles === //
import 'react-datepicker/dist/react-datepicker.css';
import styled from '@emotion/styled';
import { breakpoints, colors, dimensions, fonts, mixins, respondFrom } from '@styles';
import { css } from '@emotion/react';

const Input = styled.div`
  display: block;
  position: relative;
  margin: 0 0 48px;

  label {
    display: inline-block;
    color: ${colors.text.default};
    font-size: ${dimensions.fontSize.tiny}px;
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  input {
    display: block;
    border: 1px solid ${colors.ui.grayDark};
    background-color: ${colors.white};
    font-family: ${fonts.default};
    font-size: ${dimensions.fontSize.regular}px;
    font-weight: 300;
    color: ${colors.text.default};
    outline: none;
    padding: 16px 28px 15px 20px;
    width: 100%;
    max-width: 318px;
    border-radius: 3px 0 0 3px;

    &::placeholder {
      color: ${colors.text.placeholder};
      font-family: ${fonts.default};
    }

    &:focus {
      border-color: ${colors.text.default};
    }

    &.is-error {
    }
  }
`;

const Sublabel = styled.p`
  font-size: 15px;
  margin: 4px 0 0;
  line-height: 18px;
`;

const Error = styled.p`
  color: ${colors.text.error};
  font-size: 15px;
  margin: 4px 0 0;
  line-height: 18px;
`;

const DatepickerWrapper = styled.div`
  display: flex;
`;

const CalendarButton = styled.button`
  outline: none;
  border: none;
  box-shadow: none;
  width: 82px;
  background-color: ${colors.ui.grayDark};
  cursor: pointer;
  border-radius: 0 3px 3px 0;
  border: 1px solid ${colors.ui.grayDark};
  border-left: none;
  background-image: url(${caledarIconYellow});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 21px;
  ${mixins.transitionDefault};

  ${respondFrom(
    breakpoints.lg,
    css`
      &:hover {
        background-color: ${colors.ui.main};
        background-image: url(${caledarIconBlack});
      }
    `
  )}
`;

interface FormikDatepickerProps {
  name: string;
  placeholder: string;
  label: string;
  sublabel?: string;
}

const FormikDatepicker = ({ name, placeholder, label, sublabel }: FormikDatepickerProps) => {
  const { t } = useTranslation();
  const [datapickerOpen, toggleDatapickerOpen] = useState<boolean>(false);
  const locale = {
    pl: localePl,
    ua: localeUA,
  };

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      toggleDatapickerOpen(false);
    }
  }, []);
  const escClickFunction = useCallback((event) => {
    const classList = Array.from(event.target.classList);
    const datepicker = document.querySelector('.react-datepicker-popper');

    if (!classList.find((clss) => (clss as string) === 'datepicker-open-btn')) {
      if (
        datepicker &&
        !classList.find((clss) => (clss as string).includes('react-datepicker__'))
      ) {
        toggleDatapickerOpen(false);
      }
    }
  }, []);

  registerLocale('locale', locale[t('language')]);
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    document.addEventListener('click', escClickFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
      document.removeEventListener('click', escClickFunction, false);
    };
  }, []);

  return (
    <div>
      <Field name={name}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => {
          return (
            <Input>
              <label>{label}</label>
              <DatepickerWrapper>
                <DatePicker
                  name={name}
                  className="datepicker"
                  selected={field.value}
                  onChange={(value) => setFieldValue(name, value || '')}
                  onSelect={() => toggleDatapickerOpen(false)}
                  open={datapickerOpen}
                  locale="locale"
                  dateFormat="P" // suitable for the location
                  placeholderText={placeholder}
                  fixedHeight
                  popperPlacement="right"
                  popperModifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 80],
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        rootBoundary: 'viewport',
                        tether: false,
                        altAxis: true,
                      },
                    },
                  ]}
                  showPopperArrow={false}
                  customInput={
                    <MaskedTextInput
                      type="text"
                      mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                    />
                  }
                />

                <CalendarButton
                  className="datepicker-open-btn"
                  type="button"
                  onClick={() => toggleDatapickerOpen(!datapickerOpen)}
                ></CalendarButton>
              </DatepickerWrapper>
              {sublabel && (
                <div>
                  <Sublabel>{sublabel}</Sublabel>
                </div>
              )}
              <div>
                {meta.touched && meta.error && (
                  <>
                    <Error>{meta.error}</Error>
                  </>
                )}
              </div>
            </Input>
          );
        }}
      </Field>
    </div>
  );
};

export default FormikDatepicker;
