import React from 'react';

// === Components === //
import Checkbox from '@components/common/Checkbox';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, dimensions, respondFrom } from '@styles';
import { ErrorMessage } from 'formik';
import { css } from '@emotion/react';

const CheckboxList = styled.div`
  margin-bottom: 40px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-bottom: 48px;
    `
  )}
`;

const CheckboxListLabel = styled.label`
  display: block;
  color: ${colors.text.default};
  font-size: ${dimensions.fontSize.tiny}px;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const CheckboxListWrapper = styled.div`
  display: flex;
  flex-flow: column;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row wrap;
    `
  )}
`;

const CheckboxItemLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 25px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      margin-right: 60px;
      margin-bottom: 0;
    `
  )}

  > span {
    display: flex;
    margin-right: 16px;
  }
`;

const Error = styled.p`
  color: ${colors.text.error};
  font-size: 15px;
  margin: 4px 0 0;
  line-height: 18px;
`;

interface FormikCheckboxListProps {
  name: string;
  label: string;
  list: CheckboxData[];
}

interface CheckboxData {
  name: string;
  value: string;
}

const FormikCheckboxList = ({ name, label, list }: FormikCheckboxListProps) => {
  return (
    <CheckboxList>
      <CheckboxListLabel>{label}</CheckboxListLabel>
      <CheckboxListWrapper>
        {list.map((checkbox) => (
          <CheckboxItemLabel key={checkbox.value}>
            <span>
              <Checkbox name={name} value={checkbox.value} />
            </span>
            {checkbox.name}
          </CheckboxItemLabel>
        ))}
      </CheckboxListWrapper>
      <ErrorMessage name={name}>{(msg) => <Error>{msg}</Error>}</ErrorMessage>
    </CheckboxList>
  );
};

export default FormikCheckboxList;
