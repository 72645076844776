import React from 'react';

// === Assets === //
import tickIcon from '@assets/svg/tick.svg';

// === Components === //
import { Field } from 'formik';

// === Styles === //
import styled from '@emotion/styled';
import { colors } from '@styles';

const CheckboxStyled = styled(Field)`
  position: relative;
  width: 34px;
  height: 34px;
  border: none;
  margin: 0;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &::before {
    border: 1px solid ${colors.ui.grayDark};
    border-radius: 3px;
    background-color: ${colors.white};
  }

  &::after {
    background-image: url(${tickIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 17px;
    display: none;
  }

  &:checked {
    &::before {
      background-color: ${colors.ui.main};
    }

    &::after {
      display: block;
    }
  }
`;

interface CheckboxProps {
  name: string;
  value: string;
}

const Checkbox = ({ name, value }: CheckboxProps) => {
  return <CheckboxStyled type="checkbox" name={name} value={value} />;
};

export default Checkbox;
