import React from 'react';

// === Components === //
import Container from '@components/common/Container';
import Paragraph from '@components/common/Paragraph';

// === Helpers === //
import { useTranslation } from 'gatsby-plugin-react-i18next';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, fluidRange, respondFrom } from '@styles';
import { css } from '@emotion/react';

const MAATWrapper = styled.div`
  padding-top: 63px;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding-top: 146px;
    `
  )}

  h1 {
    font-size: 27px;
    line-height: 32px;

    ${respondFrom(
      breakpoints.md,
      css`
        ${fluidRange('fontSize', '40px', '72px')}
        ${fluidRange('lineHeight', '40px', '72px')}
      `
    )}
  }
`;

const MAATDescription = styled.div`
  max-width: 636px;
  margin-top: 20px;
  margin-bottom: 40px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-top: 40px;
      margin-bottom: 56px;
    `
  )}
`;

const MakeAnApplicationTitle = () => {
  const { t } = useTranslation();

  return (
    <MAATWrapper>
      <Container>
        <>
          <h1>{t('maa_title')}</h1>
          <MAATDescription>
            <Paragraph htmlAsString={t('maa_description')} className="m16" />
          </MAATDescription>
        </>
      </Container>
    </MAATWrapper>
  );
};

export default MakeAnApplicationTitle;
