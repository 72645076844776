import React from 'react';

// === Components === //
import { Field } from 'formik';

// === Styles === //
import styled from '@emotion/styled';
import { colors, dimensions, fonts } from '@styles';

const Textarea = styled.div`
  display: block;
  position: relative;
  margin: 0 0 48px;

  label {
    display: inline-block;
    color: ${colors.text.default};
    font-size: ${dimensions.fontSize.tiny}px;
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  textarea {
    display: block;
    border: 1px solid ${colors.ui.grayDark};
    background-color: ${colors.white};
    font-family: ${fonts.default};
    font-size: ${dimensions.fontSize.regular}px;
    font-weight: 300;
    color: ${colors.text.default};
    outline: none;
    padding: 16px 28px 15px 20px;
    width: 100%;
    max-width: 612px;
    border-radius: 3px;

    &::placeholder {
      color: ${colors.text.placeholder};
      font-family: ${fonts.default};
    }

    &:focus {
      border-color: ${colors.text.default};
    }

    &.is-error {
    }
  }
`;

const Sublabel = styled.p`
  font-size: 15px;
  margin: 4px 0 0;
  line-height: 18px;
`;

const Error = styled.p`
  color: ${colors.text.error};
  font-size: 15px;
  margin: 4px 0 0;
  line-height: 18px;
`;

interface FormikTextAreaProps {
  name: string;
  placeholder: string;
  label: string;
  sublabel?: string;
}

const FormikTextArea = ({ name, placeholder, label, sublabel }: FormikTextAreaProps) => {
  return (
    <div>
      <Field name={name}>
        {({
          field, // { name, value, onChange, onBlur }
          form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => {
          return (
            <Textarea>
              <label>{label}</label>
              <textarea
                rows={6}
                {...field}
                className={meta.touched && meta.error ? 'is-error' : ''}
                placeholder={placeholder}
                // maxLength={maxlength}
              />
              {sublabel && (
                <div>
                  <Sublabel>{sublabel}</Sublabel>
                </div>
              )}
              <div>
                {meta.touched && meta.error && (
                  <>
                    <Error>{meta.error}</Error>
                  </>
                )}
              </div>
            </Textarea>
          );
        }}
      </Field>
    </div>
  );
};

export default FormikTextArea;
