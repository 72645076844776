import React from 'react';

// === Components === //
import MakeAnApplication from '@components/template/MakeAnApplication';

// === Helpers === //
import { graphql } from 'gatsby';

const MakeAnApplicationPage = () => {
  return <MakeAnApplication />;
};

export default MakeAnApplicationPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
