import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { breakpoints, colors, fonts, mixins, respondFrom } from '@styles';
import React from 'react';

const ButtonWrapper = styled.div``;

const ButtonCustom = styled.button<ButtonCustomProps>`
  display: inline-block;
  background-color: ${colors.ui.main};
  box-shadow: none;
  outline: none;
  color: ${colors.text.default};
  padding: 24px 73px 22px 72px;
  border-radius: 80px;
  border: none;
  font-size: 18px;
  font-family: ${fonts.extraBold};
  font-weight: 800;
  cursor: pointer;
  text-transform: uppercase;
  ${mixins.transitionDefault};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  ${respondFrom(
    breakpoints.lg,
    css`
      &:hover {
        background-color: ${colors.ui.grayDark};
        color: ${colors.white};
      }
    `
  )};
`;

interface ButtonProps {
  children: React.ReactChild;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
}

interface ButtonCustomProps {
  disabled: boolean;
}

const Button = ({ children, type = 'button', disabled = false }: ButtonProps) => {
  return (
    <ButtonWrapper>
      <ButtonCustom type={type} disabled={disabled}>
        {children}
      </ButtonCustom>
    </ButtonWrapper>
  );
};

export default Button;
